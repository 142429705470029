.search-brewers{
  background-color: $blue;
  top: 2rem;

  p{
    color:$white;
  }

  button{
    width:100%;
    color: $blue;
  }
}