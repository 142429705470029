.search-row, .news-row{
  border: solid 1px $gray-300;

  .thumbnail{
    overflow: hidden;
    display: block;
    background-color: $amber;

    img{
      @include multiTransition();
      width: 100%;
    }

  }

  .title{
    font-size: 1.25rem;
  }

  .text{}


  &:hover{
    border: solid 1px transparentize($blue,0.5);
    @include boxShadow();

    .thumbnail{

      img{
        transform: scale(1.15);
      }

    }

  }

}