.pagination{

  .page-item{
    margin:5px;

    a {
      border: none;
      background-color: $blue;
      color: $white;
    }

    .pre, .next{
        background-color:transparent;
        color: $yeast;
    }

  }

}
