h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: $letter-spacing-base;
  text-transform: uppercase;
}

h1 {
  font-size: 3.5rem;
  font-weight: 100;
}

p {
  font-family: Arial, sans-serif;
}

li {
  font-family: Arial, sans-serif;
}

blockquote {
  border-left: 4px solid $yeast;
  padding-left: 20px;
  font-size: 1.75rem;
  line-height: 2.2rem;
}


// Letter Spacing
.text-spaced {
  letter-spacing: $letter-spacing-base;
}


hr {
  &.hop {
    border: none;
    border-bottom: dashed 1px theme-color('primary');
    position: relative;
    width: 100%;

    &:after {
      content: url('/assets/img/after-hr.svg');
      padding-left: map_get($spacers, 5);
      padding-right: map_get($spacers, 5);
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 0;
      @include translate(-50%, -50%);
    }
  }

}
