main.meetTheBrewers{

  .sidebar{

    .brewer-logo{
      width: 100%;
      border: 1px solid $gray-300;
    }

    .info-box{
      overflow: hidden;

      .header{
        background-color: $blue;

        h4{
          margin-bottom: 0;
        }

      }

    }

  }

}
