#global-nav {
  padding-top:40px;
  padding-bottom:40px;
	.nav-item {
		.nav-link {
			text-transform: uppercase;
            color: $white;
            letter-spacing:0.1rem;
            font-size: 0.75rem;
            border-bottom: solid 3px $blue;
            padding:0.25rem;

            &:hover, &:focus, &:active {
              border-bottom: 3px solid $light-blue;
            }

            &.active{
              border-bottom: 3px solid $light-blue;
            }
		}

        .dropdown-menu{
          margin: 0;
          padding: 0;
          background-color: $light-blue;
          border: 0;

          a{
            color: white;
            font-size: 0.85rem;

            &:hover{
              background-color: darken($light-blue,3);
              color: $amber;
            }

          }

        }

	}

  #search-input{
    @include borderRadius(50px);
    overflow: hidden;
    @include multiTransition();

    &:hover{
      transform: scale(1.05);
    }

    .input-group-prepend{
      span{
        background-color: $white;
        border:none;
      }
    }

    input{
      border:none;
      &:focus{
        box-shadow:none;
      }
    }

  }

}