.brewer {
  .row {
    .thumbnail {
      img {
        width: 100%;
        border: solid 1px $gray-300;
      }
    }

    .btn {
      @include media-breakpoint-only(xs){
        width: 100%;
      }

      @include media-breakpoint-up(sm) {
        float: right;
      }
    }

    hr {
      border: none;
      border-bottom: dashed 1px theme-color('primary');
      position: relative;
      width: 100%;

      &:after {
        content: url('/assets/img/after-hr.svg');
        padding: map_get($spacers, 4);
        background-color: $white;
        position: absolute;
        top: 0;
        left: 50%;
        @include translate(-50%, -50%);
      }
    }
  }
}
