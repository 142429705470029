@mixin borderRadius($radius){
  -webkit-border-radius:  $radius;
  -moz-border-radius:     $radius;
  border-radius:          $radius;
}

@mixin multiTransition($duration: 0.2s, $type: ease-out) {

  -webkit-transition: all $duration $type;
  -ms-transition:     all $duration $type;
  -moz-transition:    all $duration $type;
  -o-transition:      all $duration $type;
  transition:         all $duration $type;
}

@mixin verticalCenter($position: absolute) {

  position: $position;
  top: 50%;
  -webkit-transform:  translateY(-50%);
  -ms-transform:      translateY(-50%);
  -moz-transform:     translateY(-50%);
  -o-transform:       translateY(-50%);
  transform:          translateY(-50%);
}

@mixin horizontalCenter($position: absolute) {

  position: $position;
  left: 50%;
  -webkit-transform:  translateX(-50%);
  -ms-transform:      translateX(-50%);
  -moz-transform:     translateX(-50%);
  -o-transform:       translateX(-50%);
  transform:          translateX(-50%);
}

@mixin absoluteCenter($position: absolute) {

  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform:  translate(-50%, -50%);
  -ms-transform:      translate(-50%, -50%);
  -moz-transform:     translate(-50%, -50%);
  -o-transform:       translate(-50%, -50%);
  transform:          translate(-50%, -50%);
}

@mixin boxShadow(
  $horizontal-offset: 0px,
  $vertical-offset:   0px,
  $blur-radius:       3px,
  $blur-spread:       3px,
  $color:             $blue,
  $opacity:           0.9
) {
  -webkit-box-shadow: $horizontal-offset $vertical-offset $blur-radius $blur-spread transparentize($color, $opacity);
  -moz-box-shadow:    $horizontal-offset $vertical-offset $blur-radius $blur-spread transparentize($color, $opacity);
  box-shadow:         $horizontal-offset $vertical-offset $blur-radius $blur-spread transparentize($color, $opacity);
}

