// Custom Font
@font-face {
  font-family: 'Texta-Regular';
  src: url('../fonts/380712_C_0.eot');
  src: url('../fonts/380712_C_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/380712_C_0.woff2') format('woff2'),
  url('../fonts/380712_C_0.woff') format('woff'),
  url('../fonts/380712_C_0.ttf') format('truetype');
}

@import 'bootstrap/functions';

@import 'base/module';

@import 'bootstrap/bootstrap';

@import 'helpers/module';

@import 'core/module';

@import 'components/module';

@import 'pages/module';

@import 'vendor/module';