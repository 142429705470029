.statistic {
  text-align: center;

  &:not(:last-of-type) {
    border-bottom: 1px dashed theme-color('primary');
  }

  .stat__value {
    display: block;
    font-size: $display3-size;
    font-family: $font-family-serif;
  }

  .stat__detail {

  }
}