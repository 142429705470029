// Sticky Footer
body {
  @include flexbox;
  min-height: 100vh;
  @include flex-direction(column);

  main {
    //explorer fix
    display: block;
  }
}


// Page styling
.content-block {
  padding-top: $content-block-spacing;
  padding-bottom: $content-block-spacing;
}


// Background image defaults
.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}