main.homepage {
  header.header--hero {
    height: 70vh;
  }
}

// Statistics
// -- set colors
$colors: theme-color('secondary'), theme-color('hop'), theme-color('yeast');

// -- for each statistic, repeatedly loop through colors
main.homepage {
  .statistic {
    @for $i from 1 through length($colors) {
      &:nth-of-type(#{length($colors)}n+#{$i}) {
        color: nth($colors, $i)
      }
    }
  }
}