.find-cask-beer{
  background-color: $blue;

  .separator{
    width: 145px;
  }

  p{
    color: $white;
  }

  button, a{
    width: 100%;
    color: $blue;
  }
}