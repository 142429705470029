// Brand Colors
$white:     #ffffff !default;
$amber:     #cc8a00;
$yeast:     #cfb023;
$brew:      #74531c;
$hop:       #727337;
$light-blue:#48535b;


// Theme Colors
$theme-colors: () !default;
$theme-colors: map-merge((
        "white":        $white,
        "amber":        $amber,
        "yeast":        $yeast,
        "hop":          $hop,
        "light-blue":   $light-blue
), $theme-colors);


// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        -1: -($spacer * .25),
        -2: -($spacer * .5),
        -3: -$spacer,
        -4: -($spacer * 1.5),
        -5: -($spacer * 3),
        -6: -($spacer * 6)
), $spacers);


// Letter spacing
$letter-spacing-base: 0.125rem;


// Content Block Spacing
$content-block-spacing: ($spacer * 3);
