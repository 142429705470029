.sidebar-nav{
  border: none;
  text-transform: uppercase;
  @include border-radius($border-radius);
  overflow: hidden;

  .sidebar-header {
    background-color: $amber;

    h4{
      color: $white;
      margin-bottom: 0;
    }

  }

  .sidebar-entry{
    border: none;
    margin-bottom: 1px;
    padding: 0;

    a {
      color: $blue;
      padding: 0.8rem;
      background-color: $gray-300;
      display: block;
      font-size: 0.9em;
      padding-right: 2rem;

      &:after{
        content:'\f0da';
        font-family: FontAwesome;
        position: absolute;
        right: 1rem;
        top: 50%;
        margin-top: -10px;
      }

      &:hover{
        text-decoration: none;
        background: darken($gray-300, 10%)
      }
    }

    &.active{
      a {
        background-color: $secondary;
        color: $white;
      }
    }
  }
}