.header--hero {
  position: relative;
  background: transparentize($primary, 0.7);
  background-size: cover;
  background-position: center center;
  height: 50vh;

  .hero__content {
    background: transparentize($secondary, 0.15);
    color: $white;
    padding: map_get($spacers, 3);

    h1{
      font-size: 3.5rem;
      line-height: 3.8rem;
      letter-spacing: $letter-spacing-base;
      margin-bottom: 0;

      @include media-breakpoint-down(sm){
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      
    }
    .separator{}

    .subtitle{
      text-transform: uppercase;
      font-family: $font-family-base;
      font-weight:300;
    }

  }

}